.App {
    margin: 0 24px;
    /* display: flex;
    justify-content: center; */
    text-align: center;

    /* font-family: */
}

.app-container {
    min-width: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn-container {}

.btn-app-store {
    margin-right: 12px;
}